import React from "react";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={6}
      lesson={"Lesson 5"}
      color={"ms"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 6</H2>

        <p>Now that you have tested your rubric, you are ready to give it to someone who has never seen it before. How would you explain how it is used and why a rubric can be helpful when a person needs to analyze information?</p>
        <p>After you write some of your ideas in your science notebook, your teacher will lead a class discussion.</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

